import React, {useContext, useState, createContext, useEffect} from 'react'
import { useApolloClient } from '@apollo/client'
import {useStore} from 'contexts/StoreProvider'
import {PRODUCTS, CHANGE_PRODUCT_STATUS, ADD_PRODUCT, UPDATE_PRODUCT, DELETE_PRODUCT, SORT_PRODUCT_LIST, LITE_ADD_PRODUCT} from 'query'

const ProductContext = createContext(null);

const ProductProvider = ({children}) => {
  const apolloClient = useApolloClient();
  const {selectedStoreID} = useStore()
  const [productLists, setProductList] = useState([])

  useEffect (() => {
    if(selectedStoreID){
      getProductList(selectedStoreID)
    }
  },[selectedStoreID])

  const getProductList = async(store_id) => {
    try{
      if(store_id === null || store_id ===''){
        throw 'store_id cannot be empry or null'
      }
      let result = await apolloClient.query({
        query: PRODUCTS,
        variables: { store_id },
        fetchPolicy: "network-only",
      })

      const { result: data = [] } = result?.data?.products || {};

    
      const values = data?.map(item => {
        const brand = item.brand || ''
        const tags = item.tags || ''
        const images = item?.images.map((img, idx)=> ({uid: idx, url: img.url})).filter(item => item.url) || []
        return {...item, brand, tags, images}
      })

      values?.sort((a, b) => a.sort - b.sort)

      setProductList(values)
    } catch (err){
      console.error("ProductProvider getProductList", err)
      throw err
    }
  }

  const changeProductStatus = async(_id, newStatus) => {
    try{
      let result = await apolloClient.mutate({
        mutation: CHANGE_PRODUCT_STATUS,
        variables: {_id, status: newStatus},
      })
      const { status } = result.data.changeProductStatus
      
      if(status === 'success') insertWithNewData("switch", _id, newStatus)
      if(status !== 'success') throw result
    } catch (err){
      console.error("ProductProvider changeProductStatus", err)
      throw err
    }
  }

  const sortProductList = async(data, completeData) => {
    const input = {products: data}
    try{
      let result = await apolloClient.mutate({
        mutation: SORT_PRODUCT_LIST,
        variables: {input},
      })
      const { status } = result.data.sortProductList

      if(status === 'success') insertWithNewData('sort', null, completeData)
      if(status !== 'success') throw result
    } catch (err){
      console.error("ProductProvider sortProductList", err)
      throw err
    }
  }

  const createProduct = async (input) => {
    try{
      let result = await apolloClient.mutate({
        mutation: ADD_PRODUCT,
        variables: { input },
      })
      const { status, payload } = result.data.addProduct

      if(status === 'success') insertWithNewData('create', null, payload)
      return result?.data?.addProduct || null;
    } catch (err){
      console.error("ProductProvider createProduct", err)
      throw err
    }
  }
  
  const updateProduct = async (idx, data) => {
    var input = { ...data, store_id: selectedStoreID }
    try{
      let result = await apolloClient.mutate({
        mutation: UPDATE_PRODUCT,
        variables: { input },
      })

      if (result?.errors) throw (result?.errors?.[0]?.message);

      const { status, payload } = result.data.updateProduct

      if(status === 'success') insertWithNewData('update', idx, payload)
      if(status !== 'success') throw result
    } catch (err){
      console.log("ProductProvider updateProduct", err)
      throw err
    }
  }

  const deleteProduct = async (idx, _id) => {
    try{
      let result = await apolloClient.mutate({
        mutation: DELETE_PRODUCT,
        variables: {_id},
      })
      const { status } = result.data.deleteProduct
      
      if(status === 'success') insertWithNewData("delete", idx, {_id})
      if(status !== 'success') throw result
    } catch (err){
      console.error("ProductProvider deleteProduct", err)
      throw err
    }
  }

  const insertWithNewData = async (type, idx, data) => {
    let values = [...productLists];

    if(type === "switch") {
      values = values.map(item => {
        if(item['_id'] === idx) {
          item['status'] = data ? 1 : 0
        }
        return item
      })
    }
    
    if(type === 'sort') {
      values = data
    }

    if(type === 'create') {
      const images = data.images ? data.images.map((img, idx)=> ({uid: idx, url: img.url})) : []
      values = [...values, {...data, images}]
    }

    if(type === 'update') {
      const images = data.images ? data.images.map((img, idx)=> ({uid: idx, url: img.url})) : []
      values[idx] = { ...data, images }
    }

    if(type === "delete") {
      values = values.filter((item) => item._id !== data['_id'])
    }

    setProductList(values);
  }

  return (
    <ProductContext.Provider
      value={{
        productLists,
        sortProductList,
        changeProductStatus,
        createProduct,
        updateProduct,
        deleteProduct
      }}>
      {children}
    </ProductContext.Provider>
  );
};

const useProduct = () => {
  const contx = useContext(ProductContext);
  if (contx == null) {
    throw new Error('useProduct() called outside of a ProductProvider?');
  }
  return contx;
};

export {ProductProvider, useProduct};


