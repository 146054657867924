export const config = {
  appVersion: '2.3.41',

  /* Local */
  // GRAPHQL_HTTP: "http://localhost:8081/graphql",
  // GRAPHQL_WS: "ws://localhost:8081/wsql",

  /* Staging */
  // GRAPHQL_HTTP: "https://api.bereach.co/staging/admin/v1/graphql",
  // GRAPHQL_WS: "wss://api.bereach.co/staging/admin/v1/wsql",

  /* Production */
  GRAPHQL_HTTP: "https://api.bereach.co/admin/v2/graphql",
  GRAPHQL_WS: "wss://api.bereach.co/admin/v2/wsql",
}

export const paymentPercent = {
  sarawakPay: {
    dine_in: 0.5,
    self_pickup: 0.5,
    delivery: 0.5,
  },
  boost: {
    dine_in: 1,
    self_pickup: 1,
    delivery: 1,
  }
}

export const product_img_limit = 5;
